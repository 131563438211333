import { Typography, Link } from '@mui/material';

interface NotesContentProps {
  notes: string;
  onOpen: (title: string, content: React.ReactNode) => void;
}

export default function NotesContent({ notes, onOpen }: NotesContentProps) {
  return (
    <Link
      sx={{ cursor: 'pointer' }}
      underline="hover"
      onClick={() =>
        onOpen(
          'Notes',
          <Typography variant="body1" color="text.secondary">
            {notes}
          </Typography>
        )
      }
    >
      View Note
    </Link>
  );
}
