import saveAs from 'file-saver';
import { useState } from 'react';
import { useQuery } from 'react-query';

import {
  Avatar,
  IconButton,
  Link,
  Stack,
  TablePagination,
  Tooltip,
  Typography
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DownloadIcon from '@mui/icons-material/Download';

import { Loader } from '../Loader';
import { TrackDownloadsService, TrackListResponse } from '../../client';
import slugify from 'react-slugify';
import { EditNote } from '@mui/icons-material';
import { removeExtension } from '../../utils';
import { InfoDialog } from './InfoDialog';
import NotesContent from './NotesContent';

const ROWS_PER_PAGE = 5;

export default function SubmissionsSentList() {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  const { data: submissions, isLoading } = useQuery(
    ['submissions-sent', page, rowsPerPage],
    () =>
      TrackDownloadsService.getUserSubmissionsApiTrackDownloadsSubmissionsSentGet(
        page,
        rowsPerPage
      ),
    {
      keepPreviousData: true
    }
  );
  if (isLoading) return <Loader />;

  if (!submissions || submissions.items.length === 0)
    return <div>You haven't sent any submissions yet</div>;

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page + 1);
  };

  const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const getGenres = (track: TrackListResponse) => {
    if (!track.genres) return '';
    return track.genres.map((genre) => genre.name).join(', ');
  };

  const handleDialogOpen = (title: string, content: React.ReactNode) => {
    setDialogTitle(title);
    setDialogContent(content);
    setInfoDialogOpen(true);
  };

  const handleDialogClose = () => {
    setInfoDialogOpen(false);
    setDialogTitle('');
    setDialogContent(null);
  };

  return (
    <TableContainer sx={{ mb: 4 }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">TO ARTIST</TableCell>
            <TableCell align="left">ORIGINAL TRACK</TableCell>
            <TableCell align="left">SUBMITTED PROJECT</TableCell>
            <TableCell align="left">UPLOAD DATE</TableCell>
            <TableCell align="left">NOTES</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {submissions.items.map((submission) => (
            <TableRow
              key={submission.id}
              hover
              role="checkbox"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">
                <Link
                  href={`/artist/${submission.track.user?.id}/${slugify(submission.track.user?.profile_name)}`}
                  sx={{ textDecoration: 'none' }}
                >
                  <Tooltip title={submission.track.user?.profile_name}>
                    <Stack flexDirection="row" alignItems="center" gap={2} sx={{ maxWidth: 200 }}>
                      <Avatar src={submission.track.user?.avatar_url} />
                      <Typography variant="body2" color="text.secondary" noWrap>
                        {submission.track.user?.profile_name}
                      </Typography>
                    </Stack>
                  </Tooltip>
                </Link>
              </TableCell>
              <TableCell align="left">
                <Link
                  href={`/track/${submission.track.id}/${slugify(submission.track.name)}`}
                  sx={{ textDecoration: 'none' }}
                >
                  <Tooltip title={submission.track.name}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      noWrap
                      sx={{ maxWidth: 150 }}
                    >
                      {submission.track.name}
                    </Typography>
                  </Tooltip>
                </Link>
              </TableCell>
              <TableCell align="left">
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  sx={{ maxWidth: 180 }}
                >
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {submission.data_url}
                  </Typography>
                  <Tooltip title="Download my submission">
                    <IconButton
                      aria-label="download"
                      onClick={() =>
                        saveAs(
                          submission.data_url as any,
                          `${submission.track.user?.profile_name}-submission-${submission.upload_date}`
                        )
                      }
                    >
                      <DownloadIcon sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body2" color="text.secondary">
                  {new Date(submission.upload_date).toLocaleDateString()}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {submission.notes && (
                  <NotesContent notes={submission.notes} onOpen={handleDialogOpen} />
                )}
                <InfoDialog
                  open={infoDialogOpen}
                  title={dialogTitle}
                  content={dialogContent}
                  onClose={handleDialogClose}
                />
              </TableCell>
              <TableCell align="left">
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                >
                  <Tooltip title="Modify my submission">
                    <IconButton
                      aria-label="edit"
                      href={`/track/${submission.track.id}/${slugify(removeExtension(submission.track.name))}?submission=open`}
                    >
                      <EditNote />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={submissions.total || 0}
        rowsPerPage={rowsPerPage}
        page={(submissions.page || 1) - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleOnRowsPerPageChange}
      />
    </TableContainer>
  );
}
