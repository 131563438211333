import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { useState } from 'react';
import { genres } from './genres';
import { Track } from '../../client';

interface GenrePickerProps {
  selectedGenres: string[];
  onChange: (...event: any[]) => void;
}

export const GenrePicker = ({ selectedGenres, onChange }: GenrePickerProps) => {
  const handleGenreSelect = (_: React.ChangeEvent<{}>, value: string[]) => {
    onChange(value);
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Autocomplete
        multiple
        options={genres}
        value={selectedGenres}
        onChange={handleGenreSelect}
        renderTags={(value, getTagProps) =>
          value.map((genre, index) => <Chip label={genre} {...getTagProps({ index })} />)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Genres of the audio"
            placeholder="Select genres"
            InputProps={{
              ...params.InputProps,
              sx: {
                '.MuiAutocomplete-input': {
                  minWidth: '100px !important'
                }
              }
            }}
          />
        )}
      />
    </Box>
  );
};
