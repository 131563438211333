import { useMediaQuery, useTheme } from '@mui/material';
import { MutableRefObject, forwardRef, ForwardedRef } from 'react';
import AudioPlayer from 'react-modern-audio-player';
import { usePlaylist } from '../../hooks/usePlaylist';

export type PlaylistItem = {
  name: string;
  writer: string;
  img: string;
  src: string;
  id: number;
  genres: string[];
};

type PlayerWrapperProps = {
  playList: PlaylistItem[];
  currentTrack: PlaylistItem | null;
};

const PlayerWrapper = forwardRef(
  ({ playList, currentTrack }: PlayerWrapperProps, ref: ForwardedRef<HTMLAudioElement>) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const { isPlaying } = usePlaylist();

    return (
      currentTrack && (
        <AudioPlayer
          rootContainerProps={{
            colorScheme: 'dark'
          }}
          // key={`${currentTrack.id}`}
          playList={playList}
          audioRef={ref as MutableRefObject<HTMLAudioElement>}
          audioInitialState={{
            volume: 0.2,
            curPlayId: currentTrack ? currentTrack.id : 0,
            isPlaying: isPlaying
          }}
          placement={{
            player: 'bottom-left'
          }}
          activeUI={{
            all: !isTablet,
            playButton: true,
            artwork: !isMobile,
            repeatType: false,
            prevNnext: true,
            trackInfo: true,
            playList: undefined,
            progress: 'waveform'
          }}
        />
      )
    );
  }
);

export default PlayerWrapper;
