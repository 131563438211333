import * as React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SwipeableDrawer
} from '@mui/material';
import { Verified, Stars, Close } from '@mui/icons-material';

import { AuthService } from '../client';
import MuzaLogo from './MuzaLogo';
import SearchInput from './SearchInput';
import { useProfile } from '../hooks/useProfile';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { generateCldUrl } from '../utils';
import { useEffect } from 'react';

const pages = [
  ['Home', '/'],
  ['Pricing', '/pricing']
  // ['Search', '/search']
];

const profilePages = [
  { to: 'profile/details', label: 'My Details' },
  { to: 'profile/downloads', label: 'Downloads' },
  { to: 'profile/uploads', label: 'Uploads' },
  { to: 'profile/billing', label: 'Billing' }
];

const planIcons: { [key: string]: React.ReactNode } = {
  free: null,
  premium: <Verified sx={{ fontSize: '1rem', color: 'text.primary' }} />,
  pro: <Stars sx={{ fontSize: '1rem', color: 'text.primary' }} />
};

const plansReadables: { [key: string]: string } = {
  free: 'Basic (Free)',
  premium: 'Advanced',
  pro: 'Pro - Artist'
};

export default function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const { data: currentUser, isLoading: isUserLoading } = useCurrentUser();
  const { data: profile, isLoading: isProfileLoading } = useProfile();

  const location = useLocation();
  const queryClient = useQueryClient();

  const isActiveLink = (path: string) => location.pathname.includes(path);

  const logOut = useMutation(AuthService.logoutApiAuthLogoutGet, {
    onSuccess: () => {
      queryClient.invalidateQueries(['currentUser']);
      window.location.href = `/login?next=${location.pathname}`;
    }
  });

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleCloseUserMenu();
  }, [location]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const getRedirectPath = () => {
    const skipPaths = ['/login', '/signup', '/forget'];
    return skipPaths.includes(location.pathname) ? '/' : location.pathname;
  };

  const profile_avatar_url =
    profile?.avatar_url && generateCldUrl(profile?.avatar_url || '', 'avatar');

  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        backdropFilter: 'blur(2px)',
        boxShadow: 'none',
        borderBottom: '1px solid rgba(255,255,255,0.0)',
        backgroundColor: 'rgba(255,255,255,0.1)'
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'white',
              letterSpacing: '0rem',
              textDecoration: 'none',
              alignItems: 'center',
              gap: 1
            }}
          >
            <img src="/assets/muza-beta.png" alt="Muza Logo" style={{ height: 40 }} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="burger"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <nav aria-label="menu-appbar">
              <SwipeableDrawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableSwipeToOpen={false}
                ModalProps={{
                  keepMounted: true
                }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
              >
                {/* close button */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <Close />
                  </IconButton>
                </Box>
                <Stack sx={{ px: 2, pt: 1 }}>
                  <Divider />
                  <SearchInput />
                </Stack>
                <List>
                  {pages.map(([page, location]) => (
                    <ListItem key={page} disablePadding>
                      <ListItemButton component="a" href={location}>
                        <ListItemText primary={page} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                {currentUser?.email && (
                  <React.Fragment>
                    <Divider />
                    <List>
                      {profilePages.map((item) => (
                        <ListItem key={item.to} disablePadding>
                          <ListItemButton
                            component={Link}
                            to={item.to}
                            selected={isActiveLink(item.to)}
                            onClick={toggleDrawer(false)}
                          >
                            <ListItemText primary={item.label} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                )}
              </SwipeableDrawer>
            </nav>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '0rem',
              color: 'white',
              textDecoration: 'none'
            }}
          >
            <img src="/assets/muza-beta.png" alt="Muza Logo" style={{ height: 30 }} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {pages.map(([page, location]) => (
              <Button
                key={page}
                href={location}
                sx={{
                  my: 2,
                  px: 2,
                  color: 'white',
                  textTransform: 'uppercase !important',
                  fontWeight: 'bold'
                }}
              >
                {page}
              </Button>
            ))}
            <Box sx={{ flexGrow: 0 }}>
              <SearchInput />
            </Box>
          </Box>
          {!isUserLoading && !isProfileLoading && !currentUser && !profile && (
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={`/login?next=${getRedirectPath()}`}
              sx={{
                my: { xs: 1, sm: 2 },
                mr: { xs: 1, sm: 2 },
                whiteSpace: 'nowrap',
                textTransform: 'uppercase !important',
                fontWeight: 'bold'
              }}
            >
              Log In
            </Button>
          )}
          <Box sx={{ flexGrow: 0 }}>
            {(!isUserLoading || !isProfileLoading || currentUser || profile) && (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, cursor: 'pointer' }}>
                  <Avatar
                    alt={profile?.profile_name ? profile.profile_name.toUpperCase() : ''}
                    src={profile_avatar_url}
                    sx={{
                      width: 32,
                      height: 32,
                      color: '#fff',
                      backgroundColor: '#ffffff3f'
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {currentUser?.email && (
                <MenuItem>
                  <Stack spacing={1}>
                    <Typography textAlign="center">{currentUser.email}</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {planIcons[profile?.plan || 'free']}
                      <Typography textAlign="left" variant="body2" color="text.secondary">
                        {plansReadables[profile?.plan || 'free']}
                      </Typography>
                    </Stack>
                  </Stack>
                </MenuItem>
              )}
              {currentUser && <Divider />}
              {currentUser?.email && [
                <MenuItem
                  key="upload-music"
                  component={NavLink}
                  to={profile?.plan === 'pro' ? '/profile/upload-music' : '/pricing'}
                >
                  <Tooltip
                    title={profile?.plan !== 'pro' && 'Request Pro account to unlock this feature'}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography
                        textAlign="center"
                        sx={{
                          color: profile?.plan === 'pro' ? 'text.primary' : '#808080'
                        }}
                      >
                        Upload Sketch
                      </Typography>
                      {profile?.plan !== 'pro' && (
                        <Stars sx={{ fontSize: '1rem', color: 'text.primary' }} />
                      )}
                    </Stack>
                  </Tooltip>
                </MenuItem>,
                <MenuItem
                  key="uploads"
                  component={NavLink}
                  to={profile?.plan === 'pro' ? '/profile/uploads' : '/pricing'}
                >
                  <Tooltip
                    title={profile?.plan !== 'pro' && 'Request Pro account to unlock this feature'}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography
                        textAlign="center"
                        sx={{
                          color: profile?.plan === 'pro' ? 'text.primary' : '#808080'
                        }}
                      >
                        Uploads
                      </Typography>
                      {profile?.plan !== 'pro' && (
                        <Stars sx={{ fontSize: '1rem', color: 'text.primary' }} />
                      )}
                    </Stack>
                  </Tooltip>
                </MenuItem>,
                <MenuItem key="downloads" component={NavLink} to="/profile/downloads">
                  <Typography textAlign="center">Downloads</Typography>
                </MenuItem>,
                <MenuItem key="my-account" component={NavLink} to="/profile/details">
                  <Typography textAlign="center">My Account</Typography>
                </MenuItem>,
                <MenuItem key="log-out" onClick={() => logOut.mutate()}>
                  <Typography textAlign="center">Log Out</Typography>
                </MenuItem>
              ]}
              {!currentUser?.email && [
                <MenuItem key="log-in" component={NavLink} to={`/login?next=${getRedirectPath()}`}>
                  <Typography textAlign="center">Log In</Typography>
                </MenuItem>,
                <MenuItem key="sign-up" component={NavLink} to="/signup">
                  <Typography textAlign="center">Sign Up</Typography>
                </MenuItem>
              ]}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
