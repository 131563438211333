import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import {
  ApiError,
  SearchResult,
  TrackDownloadResponse,
  TrackDownloadsService,
  TrackListResponse
} from '../../client';
import { TrackRowPlayer } from '../TrackRowPlayer';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useAuth } from '../../context/AuthProvider';
import { removeExtension } from '../../utils';
import { useAudioWavePlayer } from '../../context/AudioWavePlayerContext';
import { useProfile } from '../../hooks/useProfile';
import SocialPopup from '../SocialPopup';
import slugify from 'react-slugify';
import saveAs from 'file-saver';

interface TrackRowProps {
  track: TrackListResponse | SearchResult | undefined;
}
export default function TrackRow({ track }: TrackRowProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  const { isPlaying: isCurrentPlaying, setIsPlaying: setIsCurrentPlaying } = useAudioWavePlayer();
  const { data: profile } = useProfile();

  const { createSnackBar } = useSnackBarContext();

  const downloadMutation = useMutation<TrackDownloadResponse, ApiError, number>(
    (requestBody) => TrackDownloadsService.createDownloadTrackApiTrackDownloadsPost(requestBody),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(`never-downloaded-track-${track?.id}`);
        saveAs(track?.data_url as any, `${track?.user?.profile_name}-${track?.name}`);
        createSnackBar({
          content: (
            <span>
              The track was added to <Link href="/profile/downloads" color="primary.main">your downloads</Link>
            </span>
          ),
          autoHide: true,
          severity: 'success',
          timeout: 10000
        });
      },
      onError: async (error: ApiError) => {
        if (error.status < 500) {
          createSnackBar({
            content: error.body.detail,
            autoHide: true,
            severity: 'info'
          });
        } else {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );
  if (!track) return null;

  const trackNameWithoutExt = removeExtension(track.name);

  const handlePlayPause = (track_id: number) => {
    if (isCurrentPlaying !== track_id) {
      setIsCurrentPlaying(track_id);
    }
  };

  const hasAccessToPremiumArtists =
    isAuthenticated && profile?.permissions?.includes('access_premium_artists');

  const isPremiumContent = (track: TrackListResponse) => track?.user?.account_type === 3;

  const handleDownload = (track: TrackListResponse) => {
    if (isAuthenticated && !isPremiumContent(track)) {
      downloadMutation.mutate(track?.id);
    } else if (hasAccessToPremiumArtists && isPremiumContent(track)) {
      downloadMutation.mutate(track?.id);
    } else if (isAuthenticated && isPremiumContent(track)) {
      downloadMutation.mutate(track?.id);
    } else {
      createSnackBar({
        content: 'Please sign-in to download',
        autoHide: true,
        severity: 'info'
      });
    }
  };

  return (
    <Card
      sx={{
        px: { xs: 1, sm: 1, md: 2 },
        py: { xs: 1, sm: 1, md: 2 },
        width: '100%',
        '&:hover': {
          backgroundColor: '#ffffff2f'
        }
      }}
    >
      <Grid
        container
        spacing={{
          xs: 1,
          sm: 1,
          md: 2
        }}
        alignItems="center"
        alignContent="space-between"
        justifyContent="space-between"
      >
        <Grid item xs={10} sm={9} md={8}>
          <TrackRowPlayer
            src={track.preview_url || ''}
            track_id={track.id}
            track_name={trackNameWithoutExt}
            track_artist={track?.user?.profile_name}
            track_artist_id={track?.user?.id}
            track_image={track.image_url}
            handlePlayPause={handlePlayPause}
            size="small"
          />
        </Grid>
        <Grid item xs={2} sm={3} md={2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            alignSelf="end"
            justifyContent="start"
            sx={{
              width: '100%',
              alignItems: {
                xs: 'center',
                sm: 'start'
              }
            }}
          >
            <Box
              sx={{
                display: { xs: 'none', sm: 'block' }
              }}
            >
              <Typography
                color="text.secondary"
                component="div"
                sx={{
                  fontSize: { xs: '0.7rem', md: '0.8rem' }
                }}
              >
                Genre: {track.genres?.map((genre) => genre.name).join(', ')}
              </Typography>
              <Typography
                color="text.secondary"
                component="div"
                sx={{ fontSize: { xs: '0.7rem', md: '0.8rem' } }}
              >
                {track.bpm} bpm, {track.scale}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="start"
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: {
                  xs: 'column',
                  sm: 'row'
                }
              }}
            >
              <Tooltip title="Download">
                <IconButton aria-label="download" onClick={() => handleDownload(track)}>
                  <DownloadIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.4rem' } }} />
                </IconButton>
              </Tooltip>
              <SocialPopup
                text={trackNameWithoutExt}
                url={`https://muza.studio/track/${track.id}/${slugify(trackNameWithoutExt)}`}
              />
            </Box>
          </Box>
        </Grid>
        {!isTablet && !isMobile && (
          <Grid item xs={3} sm={3} md={2} alignItems="end">
            <Box display="flex" justifyContent="end">
              <Tooltip title="Download">
                <IconButton aria-label="download" onClick={() => handleDownload(track)}>
                  <DownloadIcon sx={{ fontSize: { xs: '0.9rem', sm: '1.4rem' } }} />
                </IconButton>
              </Tooltip>
              <SocialPopup
                text={trackNameWithoutExt}
                url={`https://muza.studio/track/${track.id}/${slugify(trackNameWithoutExt)}`}
              />
              {/* <Tooltip title="Add to favorites">
              <IconButton aria-label="favorite">
                <FavoriteBorderOutlinedIcon sx={{ fontSize: { xs: '0.9rem', sm: '1.4rem' } }} />
              </IconButton>
            </Tooltip> */}
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
