import React from 'react';
import {
  Box,
  Button,
  Container,
  MobileStepper,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import { useMusicUpload } from '../../context/MusicUploadContext';

import UploadAudio from './UploadAudio';
import UploadZip from './UploadZip';
import UpdateTrackInformation from './UpdateTrackInformation';
import UploadImage from './UploadImage';
import UpdateBpmAndScale from './UpdateBpmAndScale';
import { useLocation, useParams } from 'react-router-dom';
import { Track, TracksService } from '../../client';
import { useQuery } from 'react-query';
import { Loader } from '../Loader';
import BreadcrumbsPanel from '../BreadcrumbsPanel';
import { removeExtension } from '../../utils';

const steps = ['Audio File', 'Project files', 'Track Information', 'Image', 'BPM and Key'];

export default function UploadWizard() {
  const { trackId } = useParams();
  const location = useLocation();

  const { isLoading: isDetailsLoading } = useQuery(
    `track-${trackId}`,
    () => TracksService.getTrackDetailsApiTracksTrackIdGet(Number(trackId) || -1),
    {
      enabled: !!trackId,
      onSuccess: (data: Track) => {
        setTrack(data);
      }
    }
  );

  const { handleNext, handleBack, activeStep, track, setTrack, formMethods, isLoading } =
    useMusicUpload();

  const {
    formState: { isSubmitting, errors }
  } = formMethods;

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <UploadAudio />;
      case 1:
        return <UploadZip />;
      case 2:
        return <UpdateTrackInformation />;
      case 3:
        return <UploadImage />;
      case 4:
        return <UpdateBpmAndScale />;
      default:
        return 'Unknown step';
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <BreadcrumbsPanel
        breadcrumbs={[
          { label: 'Uploads', href: '/profile/uploads' },
          {
            label: trackId ? `Update track ${removeExtension(track?.name || '')}` : 'Upload Music',
            href: location.pathname
          }
        ]}
      />

      <Typography variant="h4" gutterBottom>
        {trackId ? `Update track ${removeExtension(track?.name || '')}` : 'Upload Music'}
      </Typography>
      <Paper sx={{ width: '100%', borderRadius: 3, px: 4, py: 4 }} variant="elevation">
        {isMobile && (
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Step {activeStep + 1} / 5 : {steps[activeStep]}
          </Typography>
        )}
        {!isMobile && (
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 4, mb: 1 }} variant="h6" color="#ffffffee" gutterBottom>
              Congratulations!
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4 }} color="#ffffff7f">
              {trackId ? (
                <span>
                  Your track <b>{track?.name}</b> is now updated.
                </span>
              ) : (
                <span>
                  Your track <b>{track?.name}</b> is now uploaded.
                </span>
              )}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button href="/profile/uploads">Go to uploads</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ mt: 4 }}>{!isDetailsLoading ? renderStepContent() : <Loader />}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                variant="outlined"
                disabled={activeStep === 0 || isSubmitting || isLoading}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                onClick={handleNext}
                variant="contained"
                disabled={
                  !track ||
                  isSubmitting ||
                  isLoading ||
                  (Object.keys(errors).length > 0 && !trackId)
                }
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </Container>
  );
}
