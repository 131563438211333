import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useConfirm } from 'material-ui-confirm';
import { Box, Button, Paper, Typography, TextField, Divider, Alert, Stack } from '@mui/material';
import { useTrackSubmission } from '../../context/TrackSubmissionContext';
import UploadProgress from '../UploadProgress';
import AudioDropZone from '../AudioDropZone';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useUserSubmissionDetail } from '../../hooks/api/useUserSubmissionDetail';

interface SubmitTrackRequest {
  audio_file: File;
  notes: string;
  track_id: number;
}

interface UpdateSubmissionRequest {
  audio_file: File | null;
  notes: string;
  track_id: number;
}

export default function TrackSubmissionPanel() {
  const { trackId } = useParams();
  const [searchParams] = useSearchParams();
  const { isSubmissionPanelOpen, setIsSubmissionPanelOpen } = useTrackSubmission();
  const [audio, setAudio] = useState<File | null>(null);
  const [audioDataUrl, setAudioDataUrl] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [errors, setErrors] = useState({ audio: '' });
  const [audioUploadProgress, setAudioUploadProgress] = useState<number>(0);
  const [isAudioUploaded, setIsAudioUploaded] = useState<boolean>(false);
  const [isTrackSubmitted, setIsTrackSubmitted] = useState<boolean>(false);
  const submissionPanelOpen = searchParams.get('submission') === 'open';

  const { data: submission, isLoading: isSubmissionLoading } = useUserSubmissionDetail(
    Number(trackId),
    submissionPanelOpen
  );

  useEffect(() => {
    if (isSubmissionLoading) return;
    if (!submission) return;
    if (submissionPanelOpen) {
      setIsSubmissionPanelOpen(true);
    }
    setNotes(submission.notes || '');
    setAudioDataUrl(submission.data_url || '');
  }, [submission, isSubmissionLoading]);

  const { createSnackBar } = useSnackBarContext();

  const confirm = useConfirm();

  const updateSubmissionMutation = useMutation(
    (newTrack: UpdateSubmissionRequest) => {
      return axios.put(`/api/track_downloads/submissions/sent/${trackId}`, newTrack, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          setAudioUploadProgress(percentCompleted);
          console.log(percentCompleted);
        }
      });
    },
    {
      onSuccess: (response) => {
        setIsAudioUploaded(true);
        setIsTrackSubmitted(true);
      },
      onError: (error: AxiosError<any>) => {
        const { response } = error;
        if (!response) {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
          return;
        }

        if (response.status < 500) {
          createSnackBar({
            content: error?.response?.data?.detail,
            autoHide: true,
            severity: 'info'
          });
        } else {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
        }
        setIsAudioUploaded(false);
        setErrors({ ...errors, audio: 'Error uploading audio file' });
      }
    }
  );

  const submitTrackMutation = useMutation(
    (newTrack: SubmitTrackRequest) => {
      return axios.post(`/api/track_downloads/submit_track`, newTrack, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          setAudioUploadProgress(percentCompleted);
          console.log(percentCompleted);
        }
      });
    },
    {
      onSuccess: (response) => {
        setIsAudioUploaded(true);
        setIsTrackSubmitted(true);
      },
      onError: (error: AxiosError<any>) => {
        const { response } = error;
        if (!response) {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
          return;
        }

        if (response.status < 500) {
          createSnackBar({
            content: error?.response?.data?.detail,
            autoHide: true,
            severity: 'info'
          });
        } else {
          createSnackBar({
            content: 'An error occurred. Please try again later.',
            autoHide: true,
            severity: 'error'
          });
        }
        setIsAudioUploaded(false);
        setErrors({ ...errors, audio: 'Error uploading audio file' });
      }
    }
  );

  const handleFileUpload = (acceptedFiles: File[]) => {
    setErrors({ ...errors, audio: '' });
    setAudio(acceptedFiles[0]);
    setAudioDataUrl('');
  };

  const onAudioUploadCancel = () => {
    confirm({
      description: 'Are you sure you want to cancel uploading this audio file?',
      title: 'Cancel Upload',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        setAudio(null);
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  if (isTrackSubmitted)
    return (
      <Paper sx={{ mt: 2 }}>
        <Box sx={{ p: 3, width: '100%' }}>
          {submissionPanelOpen ? (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ my: 8 }}
              spacing={2}
            >
              <Typography sx={{ textAlign: 'center' }} variant="h5" gutterBottom>
                Your submission has been updated successfully!
              </Typography>
              <Button
                href={`/profile/submissions-sent`}
                variant="contained"
                color="primary"
                size="large"
                sx={{ width: 'fit-content' }}
              >
                View submissions
              </Button>
            </Stack>
          ) : (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ my: 8 }}
              spacing={2}
            >
              <Typography sx={{ textAlign: 'center', my: 8 }} variant="h5" gutterBottom>
                Your track has been submitted successfully!
              </Typography>
              <Button
                href={`/profile/submissions-sent`}
                variant="contained"
                color="primary"
                size="large"
                sx={{ width: 'fit-content' }}
              >
                View submissions
              </Button>
            </Stack>
          )}
        </Box>
      </Paper>
    );

  return isSubmissionPanelOpen ? (
    <Paper sx={{ mt: 2 }}>
      <Box sx={{ p: 3, width: '100%' }}>
        <Typography sx={{ textAlign: 'center' }} variant="h5" gutterBottom>
          {submissionPanelOpen || audioDataUrl ? 'Edit your submission' : 'Submit a track'}
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', mt: 2 }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" color="#ffffff7f" sx={{ mt: 1, mb: 2 }} gutterBottom>
              Please provide notes about the track you are submitting. Share any important details,
              such as the mood, style, key elements, or specific ideas.
            </Typography>
            <TextField
              required
              sx={{ width: '100%' }}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              label="Notes about the track"
              minRows={8}
              multiline
            />
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 4 }} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }} color="#ffffff7f">
              Upload your version as a 16Bit 44,100 Stereo WAV file. (*Keep your project files &
              stems in case that the artist will want to make some changes etc...){' '}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Audio File
            </Typography>
            <AudioDropZone onFileUpload={handleFileUpload} />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              {(audio || submission?.data_url) && (
                <UploadProgress
                  fileName={audio?.name || submission?.track.name || ''}
                  fileSize={audio?.size || 0}
                  progress={audioDataUrl ? 100 : audioUploadProgress}
                  onCancel={onAudioUploadCancel}
                  uploaded={(audioUploadProgress === 100 && isAudioUploaded) || !!audioDataUrl}
                  url={audioDataUrl ? audioDataUrl : undefined}
                  uploadingAfterSubmit={updateSubmissionMutation.isLoading || submitTrackMutation.isLoading}
                  uploadAfter
                />
              )}
            </Box>
            {errors.audio && (
              <Alert sx={{ my: 1 }} variant="outlined" severity="error">
                {errors.audio}
              </Alert>
            )}
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          {submissionPanelOpen || audioDataUrl ? (
            <Button
              sx={{ fontSize: 18, mt: 4, mx: 'auto' }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (!trackId) return;
                updateSubmissionMutation.mutate({
                  audio_file: audio,
                  notes: notes,
                  track_id: Number(trackId)
                });
              }}
              disabled={updateSubmissionMutation.isLoading || !trackId || !notes}
            >
              {updateSubmissionMutation.isLoading ? 'Updating...' : 'Update Submission'}
            </Button>
          ) : (
            <Button
              sx={{ fontSize: 18, mt: 4, mx: 'auto' }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (!trackId) return;
                if (!audio) return;
                submitTrackMutation.mutate({
                  audio_file: audio,
                  notes: notes,
                  track_id: Number(trackId)
                });
              }}
              disabled={submitTrackMutation.isLoading || !audio || !trackId || !notes}
            >
              {submitTrackMutation.isLoading ? 'Submitting...' : 'Submit Track'}
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  ) : null;
}
