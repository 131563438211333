import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { usePlaylist } from '../../hooks/usePlaylist';
import YouTube, { YouTubeProps } from 'react-youtube';

export default function InfoSection(): JSX.Element {
  const { setIsPlaying, setCurrentTrack } = usePlaylist();

  const getResponsiveOptions = (): YouTubeProps['opts'] => {
    const width = window.innerWidth * 0.9; // 90% of the screen width
    const height = (width * 9) / 16; // Maintain 16:9 aspect ratio
    return {
      height: `${height}px`,
      width: `${width}px`,
      playerVars: {
        autoplay: 0, // Don't autoplay
        controls: 1, // Show player controls
        rel: 0, // Disable related videos
        modestbranding: 1 // Remove YouTube logo
      }
    };
  };

  const handlePlayerReady: YouTubeProps['onReady'] = (event) => {
    console.log('Player is ready');
    event.target.unMute(); // 🔥 Unmute the player
    event.target.setVolume(100); // 🔥 Set volume to 100%
  };

  const handleStateChange: YouTubeProps['onStateChange'] = (event) => {
    const YTPlayerState = event.data;
    if (YTPlayerState === 1) {
      // 1 means "PLAYING"
      setIsPlaying(false); // Stop the music
      setCurrentTrack(null); // Clear current track
    }
  };

  return (
    <Box sx={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h4"
          sx={{
            mt: 4,
            mb: 8,
            textAlign: 'center',
            verticalAlign: 'middle'
          }}
        >
          How does MUZA work?
        </Typography>

        {/* Responsive Box for YouTube Video */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%', // Aspect ratio for 16:9
            '& iframe': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '10px' // Optional for better appearance
            }
          }}
        >
          <YouTube
            videoId="MatZhriM3uQ"
            opts={getResponsiveOptions()}
            onReady={handlePlayerReady}
            onStateChange={handleStateChange}
          />
        </Box>
      </Container>
    </Box>
  );
}
