import { Button } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';

export default function SignUpExploreButton({ text }: { text?: string }) {
  const { isLoading, isAuthenticated } = useAuth();

  return (
    <>
      {isAuthenticated ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            opacity: isLoading ? 0 : 1,
            transition: 'opacity 0.3s ease',
            fontSize: 20,
            paddingX: 5
          }}
          href="/#explore"
        >
          {text ? text : 'Start Collaborating'}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            opacity: isLoading ? 0 : 1,
            transition: 'opacity 0.3s ease',
            fontSize: 18,
            paddingX: 5,
            fontWeight: 'bold',
            textTransform: 'uppercase !important'
          }}
          href="/signup"
        >
          {text ? text : 'Sign Up for Free'}
        </Button>
      )}
    </>
  );
}
