import { Stack, Link, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackBarContext } from '../../context/SnackBarContext';

interface EmailContentProps {
  email: string;
  onOpen: (title: string, content: React.ReactNode) => void;
}

export default function EmailContent({ email, onOpen }: EmailContentProps) {
  const { createSnackBar } = useSnackBarContext();

  const handleCopy = () => {
    navigator.clipboard.writeText(email);
    createSnackBar({
      content: 'Email copied to clipboard!',
      severity: 'success',
      autoHide: true
    });
  };

  return (
    <Link
      sx={{ cursor: 'pointer' }}
      underline="hover"
      onClick={() =>
        onOpen(
          'Contact Email',
          <Stack display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Link href={`mailto:${email}`} variant="body1" color="primary" underline="hover">
              {email}
            </Link>
            <IconButton onClick={handleCopy}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>
        )
      }
    >
      View Email
    </Link>
  );
}
