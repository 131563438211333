import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

interface InfoDialogProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  onClose: () => void;
}

export function InfoDialog({ open, title, content, onClose }: InfoDialogProps) {
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          overflowX: 'hidden',
          whiteSpace: 'pre-wrap'
        }}
      >
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
