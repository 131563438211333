import { useQuery } from 'react-query';
import { TrackDownloadsService } from '../../client';

export const useUserSubmissionDetail = (trackId: number, submissionPanelOpen: boolean) => {
  return useQuery(
    ['submission-sent', trackId],
    () =>
      TrackDownloadsService.getUserSubmissionDetailApiTrackDownloadsSubmissionsSentTrackIdGet(
        trackId
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!trackId && submissionPanelOpen
    }
  );
};
