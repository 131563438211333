import { Box, Grid } from '@mui/material';

import { useAudioWavePlayer } from '../../context/AudioWavePlayerContext';
import {SubmissionRowPlayer} from './SubmissionRowPlayer';

interface SubmissionTrackRowProps {
  data_url?: string;
  submission_id: number;
}
export default function SubmissionTrackRow({ data_url, submission_id }: SubmissionTrackRowProps) {
  const { isPlaying: isCurrentPlaying, setIsPlaying: setIsCurrentPlaying } = useAudioWavePlayer();

  const handlePlayPause = (track_id: number) => {
    if (isCurrentPlaying !== track_id) {
      setIsCurrentPlaying(track_id);
    }
  };

  if (!data_url) return null;

  return (
    <Box
      sx={{
        px: { xs: 1, sm: 1, md: 2 },
        py: { xs: 1, sm: 1, md: 2 },
        width: '100%',
        minWidth: '300px',
      }}
    >
      <Grid
        container
        spacing={{
          xs: 1,
          sm: 1,
          md: 2
        }}
        alignItems="center"
        alignContent="space-between"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={12} md={12}>
          <SubmissionRowPlayer
            src={data_url || ''}
            submission_id={submission_id}
            handlePlayPause={handlePlayPause}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
